<template>
  <header :class="['header-lk', { _open: isOpen }]">
    <div class="header-lk__logo logo">
      <img
        src="/logo.svg"
        alt="svg"
      >
    </div>

    <button
      class="header-btn-nav"
      @click="onMobileOpen"
    >
      <Icon
        to="white"
        :type="isOpen ? 'close' : 'menu'"
      />
    </button>

    <slot />
  </header>
</template>

<style type="css" src="./header.css"></style>

<script>
export default {
  name: "Header",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onMobileOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
