<template>
  <Pagination 
    :list="contracts" 
    class="contracts"
    class-item="contracts__item"
  >
    <template v-slot:item="{ item }">
      <div class="contracts__top">
        <template v-if="item.status === 'canceled'">
          <span class="mx-4" />
          <del>{{ item.name }}</del>
          <b class="mx-4"> услуга отменена </b>
        </template>
        <template v-else>
          <Icon type="done-all" />
          <h4>{{ item.name }}</h4>
          <Button
            class="mr-3"
            type="link"
            @click="contractDeactivate(item.id)"
          >
            Отвязать карту
          </Button>
        </template>
      </div>
      <div class="documentation">
        <div class="documentation-content">
          <div class="info">
            <a
              v-for="({ name, url }, index) in item.documents"
              :key="index"
              :href="url"
              target="_blank"
            >
              {{ name }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </Pagination>
</template>

<style lang="css" src="./contracts.css"></style>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("auth");
import Pagination from "@/pages/index/components/pagination/pagination.vue";

export default {
  components: {
    Pagination,
  },
  computed: {
    ...mapGetters(["contracts"]),
  },
  mounted() {
    this.contractsGet().catch(() => {
      this.$router.push({ name: "login" });
    });
  },
  methods: {
    ...mapActions(["contractsGet", "contractDeactivate"]),
  },
};
</script>
